<div class="page-wrap h-full slate">
  <div class="session-form-hold">
    <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
    <mat-card class="custom-card">
      <mat-card-content class="mat-card-contents">
        <div class="text-center logo-div">
          <img height="42.5" width="122.5px" src="assets/images/svg-icons/logo_svg.svg" alt="">
        </div>
        <div class="auth-container">
          <router-outlet></router-outlet>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <span class="app-version" disabled *ngIf="appVer">v{{appVer}}</span>
</div>
