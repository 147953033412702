import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-av-health-tag',
  templateUrl: './av-health-tag.component.html',
  styleUrls: ['./av-health-tag.component.scss']
})
export class AvHealthTagComponent implements OnInit {
  @Input() status: number;
  healthStatus = '';
  healthStatusOptions = {
    1: 'Healthy',
    2: 'Unhealthy',
    3: 'At Risk',
    99: 'Unknown',
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  ngOnInit(): void {
    this.healthStatus = this.healthStatusOptions[this.status];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.status = changes.status ? changes.status.currentValue : this.status;
    this.healthStatus = this.healthStatusOptions[this.status];
  }

}
