import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-av-card-summary-item-inline',
  templateUrl: './av-card-summary-item-inline.component.html',
  styleUrls: ['./av-card-summary-item-inline.component.scss']
})
export class AvCardSummaryItemInlineComponent implements OnInit {

  @Input() header: string;
  @Input() value: string;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
// eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

}
