<!-- notification button -->
<button *ngIf="layoutConf.isMobile" mat-icon-button class="search" (click)="search()">
  <mat-icon [svgIcon]="'search'"></mat-icon>
</button>
<!-- notification button -->
 <button mat-icon-button class="notifications" id="notifications" (click)="gotoNotifications()">
  <mat-icon [svgIcon]="'notifications'"></mat-icon>
</button>
<!-- Account -->
<button [matMenuTriggerFor]="accountMenu" mat-icon-button id="profile" class="profile">
  <img [src]="userDetails.profile_image" *ngIf="userDetails.profile_image">
  <mat-icon [svgIcon]="'user-profile'" *ngIf="!userDetails.profile_image" class="svg-icon"></mat-icon>
  <span class="username" *ngIf="!layoutConf.isMobile"> {{user}} </span>
  <mat-icon *ngIf="!layoutConf.isMobile" class="profile-arrow" height="34px">arrow_drop_down</mat-icon>
</button>

<mat-menu #accountMenu="matMenu" id="matMenu">
  <button id="to-profile" mat-menu-item (click)="gotoProfile()">
    <span>{{ "Profile" }}</span>
  </button>
  <button mat-menu-item >
    <span>{{ "Help Center" }}</span>
</button>
  <button mat-menu-item (click)="loginService.signout()">
      <span>{{ "Logout" }}</span>
  </button>
</mat-menu>
