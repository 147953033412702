import { ActivatedRoute, Router } from "@angular/router";
import { IUser, User } from "../models/user.model";
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AccountService } from "./account.service";
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { LOCAL_STORAGE } from 'app/shared/constants/local-storage';
import { LocalStoreService } from "./local-store.service";
import { ProgressbarService } from 'app/shared/services/progressbar.service';
import { URLS } from "./urls";
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  isAuthenticated: Boolean;
  signingIn: Boolean;
  return: string;
  APP_USER: any = {};
  signedIn = false;

  constructor(
    private ls: LocalStoreService,
    private router: Router,
    private route: ActivatedRoute,
    private ps: ProgressbarService,
    private accountService: AccountService,
    private httpClient: HttpClient
  ) {
    this.route.queryParams.subscribe(params => this.return = params['return'] || '/');
  }

  async signin(email, password) {
    return await axios.post<User>(URLS.login, { email, password });
  }


  public signout() {
    axios.get(URLS.logout);
    this.ls.clear();
    this.ps.changeStatus('determinate');
    this.accountService.changeAccount('');
    this.router.navigateByUrl('sessions/signin')
  }

  async mappingData() {
    return await axios.get(URLS.mapping_data);
  }

  getUser() {
    return this.ls.getItem(LOCAL_STORAGE.APP_USER);
  }

  public isLoggedIn(): Observable<boolean> {
    return this.httpClient.get(URLS.login_check).pipe(
      map((response: any) => {
        if (response.message === 'logged in.') {
          const user = new User(response.user as IUser);
          this.ls.setItem(LOCAL_STORAGE.APP_USER, user);
          return true;
        }
        return false;
      }),
      catchError(() => throwError(false))
    );
  }
}
