<mat-sidenav-container class="app-admin-container">
  <mat-sidenav-content>
    <div class="header topbar" [perfectScrollbar]="" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="logo"><img width="120px" height="42.5px" src="assets/images/svg-icons/logo_svg.svg" alt="" class="app-logo"></div>
    </div>
    <div class="welcome-layout">
    <div class="container container-dynamic">
      <router-outlet></router-outlet>
    </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
