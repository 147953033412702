import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-av-full-card-summary-item-header',
  templateUrl: './av-full-card-summary-item-header.component.html',
  styleUrls: ['./av-full-card-summary-item-header.component.scss']
})
export class AvFullCardSummaryItemHeaderComponent implements OnInit {
  @Input() name: string;
  @Input() center: string;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
// eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {

  }

}
