<ng-container>
  <span *ngFor="let item of scheduleForm.get('publicAvailabilityFields').get('PA')['controls'];let i= index">
    <div fxLayout="row" class="pt-2" fxFlexAlign="start center" fxLayoutGap="16px" fxLayoutGap.lt-sm="0px"
      fxLayoutGap.lt-md="0px" fxLayout.lt-md="column">
      <div>
        <mat-form-field fxFlex.lt-sm="100">
          <mat-label>Time Zone</mat-label>
          <mat-select formControlName="timezone" ngDefaultControl [disableOptionCentering]="true">
            <mat-option></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxFlexAlign="start center" fxLayoutGap="16px" fxLayoutGap.lt-sm="0px"
      fxLayoutGap.lt-md="0px" fxLayout.lt-md="column">
      <mat-form-field fxFlex="50" fxFlex.lt-sm="100" fxFlex.lt-lg="50">
        <mat-label>Frequency</mat-label>
        <mat-select formControlName="frequency" (selectionChange)="validateFrequency($event)" ngDefaultControl [disableOptionCentering]="true" *ngFor="let fItem of frequencyList;">
          <mat-option [value]="fItem.value">{{fItem.text}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-md="5px"
        fxLayoutGap.lt-lg="5px" fxLayoutGap.lt-sm="0px">
        <mat-form-field fxFlex="30" fxFlex.lt-md="46" fxFlex.lt-sm="100">
          <mat-label>From Hours </mat-label>
          <mat-select formControlName="fromhours" ngDefaultControl [disableOptionCentering]="true" *ngFor="let fHour of hoursFrom;">
            <mat-option [value]="fHour.value">{{fHour.text}}</mat-option>
          </mat-select>
        </mat-form-field>
        <span class="time-sep" fxLayoutGap.lt-sm="0px">—</span>
        <mat-form-field fxFlex="30" fxFlex.lt-md="46" fxFlex.lt-sm="100">
          <mat-label> To Hours </mat-label>
          <mat-select formControlName="tohours" ngDefaultControl [disableOptionCentering]="true" *ngFor="let tHour of hoursUpto;">
            <mat-option [value]="tHour.value">{{tHour.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </span>
  <span>
    <div fxLayoutGap="5px">
      <a class="d-flex justify-content-start align-items-center circle" (click)="addMorePA()" id="addBtn">
        <mat-icon class="add-icon">add_circle</mat-icon>
        <span class="add-hour"> Add another set of hours</span>
      </a>
    </div>
  </span>
</ng-container>
