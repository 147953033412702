<app-av-dialogue-box id="actions" pop_title="Change Password" (saveData)="changePassword()" (closeDlg)="closeDialogue()" [isDisable]="!changePwdForm.valid">
  <span>
    <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
    <form [formGroup]="changePwdForm">
        <mat-form-field>
          <mat-label>Old Password</mat-label>
          <input type="password" formControlName="oldPassword" title="" matInput name="oldpassword" id="oldpassword" required
          placeholder="Old Password" value="">
          <mat-error *ngIf="changePwdForm.controls['oldPassword'].hasError('required')" >
            <span id="pwd-req">Password is required</span>
          </mat-error>
          <mat-error *ngIf="changePwdForm.controls['oldPassword'].hasError('passwordStrength')" id="old_password-pattern">
            {{changePwdForm.controls['oldPassword'].errors['passwordStrength']}}
          </mat-error>
          <mat-error class="password-err" id="password-err" *ngIf="changePwdForm.controls['oldPassword'].hasError('passErrorMessage')"> {{passErrorMessage}} </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>New Password</mat-label>
          <input type="password" matInput name="newPassword" title="" formControlName="newPassword" id="newpassword" required
            placeholder="New Password"
            value="">
            <mat-error *ngIf="changePwdForm.controls['newPassword'].hasError('required')">
              <span  id="pwd_new-req">Password is required</span>
            </mat-error>
           <mat-error *ngIf="changePwdForm.controls['newPassword'].hasError('passwordStrength')" id="password-pattern">
            {{changePwdForm.controls['newPassword'].errors['passwordStrength']}}
           </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Confirm Password</mat-label>
          <input type="password" matInput name="confirmPassword"  title="" formControlName="confirmPassword" id="confirmpassword" required
            placeholder="Confirm Password"
            value="">
            <mat-error *ngIf="changePwdForm.controls['confirmPassword'].hasError('required')">
              <span  id="confirmpassword">Confirm Password is required</span>
            </mat-error>
            <mat-error *ngIf="changePwdForm.controls['confirmPassword'].hasError('confirmPasswordValidator')" class="password-m-err"> Password does not match </mat-error>

        </mat-form-field>
      </form>
    </span>
  </app-av-dialogue-box>

