import { Component, OnInit, Input } from '@angular/core';
import appVersion from '../../../../../appVersion.json';
import { environment } from '../../../../environments/environment';
import { LOCAL_STORAGE } from "app/shared/constants/local-storage";
import { LocalStoreService } from "app/shared/services/local-store.service";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
})
export class SidenavComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('items') public menuItems: any[] = [];
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  public appVer: string;
  orgId: string;

  constructor(
    private ls: LocalStoreService
  ) {}
  
  ngOnInit() {
    if (environment.production === false) this.appVer = appVersion.appVersion;
    const orgDetails = this.ls.getItem(LOCAL_STORAGE.ACCOUNT);
    this.orgId = orgDetails ? orgDetails.id : '';
  }
}
