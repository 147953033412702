// App
import appVersion from '../../appVersion.json';

export const config = {
  production: false,
  apiUrl: '',
  mapKey: 'AIzaSyBGshlLIN85JDqu-qrDOjjQh3F6gpDaaOM',
  captchaKey: '6LePn5geAAAAAGAGTEeRWhgZCxBofZQoGS2W_hrR',
  sentryEnv: 'develop', // for sentry environment
  sentryDsn: 'https://c793d85f96ef4cf8beca0a933b7abb3d@o609694.ingest.sentry.io/6242854', // sentry dsn for dev builds
  sentrytracingOrigins: ['https://devlabmgtsite.avivefuture.com/'],
  version: appVersion.appVersion
}