<span *ngIf="!passwordReset">
  <p class="card-title"> Reset Password </p>
  <form [formGroup]="confirmationForm" (ngSubmit)="submitPassword()">
    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input type="password" matInput name="password" id="password" required
        formControlName="password" ngDefaultControl placeholder="New Password" value="">
      <mat-error *ngIf="confirmationForm.controls['password'].hasError('required')">
        <span class="pass-req">Password is required</span>
      </mat-error>
      <mat-error *ngIf="confirmationForm.controls['password'].hasError('passwordStrength')">
        <span class="password-pattern">{{confirmationForm.controls['password'].errors['passwordStrength']}}</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Confirm Password</mat-label>
      <input type="password" matInput name="confirmPassword" id="confirmPassword" required
      formControlName="confirmPassword" ngDefaultControl placeholder="Re enter password"
        value="">
      <mat-error *ngIf="confirmationForm.controls['confirmPassword'].hasError('required')"
        class="form-error-msg con-pass-req"> Confirm password is required
      </mat-error>
      <mat-error *ngIf="confirmationForm.controls['confirmPassword'].hasError('confirmPasswordValidator')" class="password-m-err"> Password does not match </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="card-buttons">
      <a [routerLink]="'/sessions/signin'" class="back-to-login mat-primary link">Login</a>
      <button mat-flat-button class="mat-primary password-button" [disabled]="confirmationForm.invalid"
          id="submit_button">Submit
      </button>
    </div>
  </form>
</span>
<div *ngIf="passwordReset" fxLayoutGap="16px" fxLayout="column" class="success-message align-items-center">
  <p class="px-4"> {{ message }}</p>
  <button class="mt-auto" mat-stroked-button (click)="goToSignin()" id="go-to-signin">Return to Login</button>
</div>
