<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">
            <!-- App Logo -->
            <div class="branding info">
                <img src="assets/images/svg-icons/logo.svg" alt="" class="app-logo" (click)="goToDashBoard()">
                <span class="app-logo-text"></span>
                <app-av-account-name *ngIf="!layoutConf.isMobile"></app-av-account-name>
            </div>
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
    </div>
</div>
