<app-av-pg>
<app-av-pg-title>
      <app-av-pg-title-heading pg_title="Profile"></app-av-pg-title-heading>
       <app-av-pg-title-buttons>
      <button mat-stroked-button id="back-button" (click)="goBack()">CANCEL</button>
      <button mat-flat-button color="primary" id="save-button" (click)="editUser()" [disabled]="!userProfileForm.valid || !userProfileForm.dirty">SAVE</button>
    </app-av-pg-title-buttons>
    </app-av-pg-title>
    <span fxLayout="column" fxLayoutGap="10px">
    <form [formGroup]="userProfileForm" class="form-controllers">
      <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="profile-info">
        <img [src]="userDetails.profile_image" *ngIf="userDetails.profile_image" class="icon-image">
        <mat-icon [svgIcon]="'user-profile'" *ngIf="!userDetails.profile_image" class="icon-display"></mat-icon>
        <span fxLayout="column" fxFlex="100">
          <button mat-icon-button class="upload-profile" (change)="uploadImage($event)" (click)="$event.target.value=null">
            <span class="uploadphoto">
              <a id="uploadPhoto" class="download-text" (click)="uploader.click($event)" >UPLOAD PHOTO</a>
              <input type="file" hidden #uploader formControlName="profilePicture" id="profilePicture">
            </span>
          </button>
          <span>
            <mat-error class="materror-outside" *ngIf="imageError !== ''" >{{imageError}}</mat-error>
            <mat-error class="materror-outside" *ngIf="imageTypeError !== ''" >{{imageTypeError}}</mat-error>
          </span>
        </span>
      </div>
      <div fxLayoutGap.lt-sm="4px"  fxLayout="column">
      <div fxLayout="row" fxFlexAlign="start center" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="4px"  id="userInfo">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput id="firstname" formControlName="firstname" placeholder="" value=""  (keypress)= "keyValidation($event)" (paste)="false" required  maxlength="150">
          <mat-error id="frstname-err" *ngIf="userProfileFormCtrls.firstname.hasError('required')">First Name is required</mat-error>
          <mat-error *ngIf="userProfileFormCtrls.firstname.hasError('blankSpaceOnly')">
            {{userProfileForm.controls.firstname.errors.blankSpaceOnly}}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput id="lastname" formControlName="lastname" placeholder="" value=""  (keypress)= "keyValidation($event)"  maxlength="150" (paste)="false" required>
          <mat-error id="lastname-err" *ngIf="userProfileFormCtrls.lastname.hasError('required')">Last Name is required</mat-error>
          <mat-error *ngIf="userProfileFormCtrls.lastname.hasError('blankSpaceOnly')">
            {{userProfileForm.controls.lastname.errors.blankSpaceOnly}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxFlexAlign="start center" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="4px" >
        <mat-form-field fxLayoutGap="16px">
          <mat-label>Email</mat-label>
          <input matInput id="email" formControlName="email" placeholder="" value=""  readonly >
          <mat-error id="email-err" *ngIf="userProfileFormCtrls.email.hasError('required')">Email is required</mat-error>
          <mat-error id="inv-email-err" *ngIf="userProfileFormCtrls.email.hasError('email')"> Invaild email address </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Phone Number</mat-label>
          <input matInput id="phonenumber" formControlName="phonenumber" placeholder="" value="" required (keypress)="ValidateInput($event)">
          <mat-error id="phone-err" *ngIf="userProfileFormCtrls.phonenumber.hasError('required')">Phone Number is required</mat-error>
          <mat-error id="valid-phone-number"
            *ngIf="userProfileFormCtrls.phonenumber.hasError('phoneNumberPattern') || userProfileFormCtrls.phonenumber.hasError('minLength') || userProfileFormCtrls.phonenumber.hasError('maxLength')">
            {{userProfileFormCtrls.phonenumber.errors['phoneNumberPattern']}}  </mat-error>
        </mat-form-field>
      </div>
    </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutAlign="start">
        <a class="download-text" id="changepwd" (click)="changePwd()" fxFlex="20" fxFlex.lt-md="20" >CHANGE PASSWORD</a>
        <a class="fail" id="delete"  (click)="goToUserDelete()" >DELETE PROFILE</a>
      </div>
    </form>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" id="notifyInfo">
      <app-av-card fxFlex="100">
        <app-av-card-title>Device Status Updates</app-av-card-title>
        <app-av-card-content>
          <div fxLayout="row" fxLayoutAlign="space-between" class="toggle-space">
            <h4 matLine >Push Notification</h4>
            <mat-slide-toggle id="pushDevice"
              [(ngModel)]="pushNotificationDevice"
              [labelPosition]="labelPosition"
              [color]="color"
              [checked]="pushNotificationDevice"
              [disabled]="disabled"
              (change)="toggle($event)"
              ngDefaultControl>
            </mat-slide-toggle>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="toggle-space">
            <h4 matLine >Email Notification</h4>
            <mat-slide-toggle id="emailDevice"
              [(ngModel)]="emailNotificationDevice"
              [labelPosition]="labelPosition"
              [color]="color"
              [checked]="emailNotificationDevice"
              [disabled]="disabled"
              (change)="toggle($event)"
              ngDefaultControl>
            </mat-slide-toggle>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="toggle-space">
            <h4 matLine class="text-column-content">Text Notification</h4>
            <mat-slide-toggle id="textDevice"
              [(ngModel)]="textNotificationDevice"
              [labelPosition]="labelPosition"
              [color]="color"
              [checked]="textNotificationDevice"
              [disabled]="disabled"
              (change)="toggle($event)"
              ngDefaultControl>
            </mat-slide-toggle>
          </div>
        </app-av-card-content>
      </app-av-card>
      <app-av-card fxFlex="100">
        <app-av-card-title>Responder Alerts</app-av-card-title>
        <app-av-card-content>
          <div fxLayout="row" fxLayoutAlign="space-between" class="toggle-space">
            <h4 matLine class="text-column-content">Push Notification</h4>
            <mat-slide-toggle id="pushResponder"
              [(ngModel)]="pushNotificationResponder"
              [labelPosition]="labelPosition"
              [color]="color"
              [checked]="pushNotificationResponder"
              [disabled]="disabled"
              (change)="toggle($event)"
              ngDefaultControl>
            </mat-slide-toggle>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="toggle-space">
            <h4 matLine class="text-column-content">Email Notification</h4>
            <mat-slide-toggle id="emailResponder"
              [(ngModel)]="emailNotificationResponder"
              [labelPosition]="labelPosition"
              [color]="color"
              [checked]="emailNotificationResponder"
              [disabled]="disabled"
              (change)="toggle($event)"
              ngDefaultControl>
            </mat-slide-toggle>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="toggle-space">
            <h4 matLine class="text-column-content">Text Notification</h4>
            <mat-slide-toggle
                [(ngModel)]="textNotificationResponder"
                [labelPosition]="labelPosition"
                [color]="color" id="textResponder"
                [checked]="textNotificationResponder"
                [disabled]="disabled"
                (change)="toggle($event)"
                ngDefaultControl>
            </mat-slide-toggle>
          </div>
        </app-av-card-content>
      </app-av-card>
    </div>
    </span>

</app-av-pg>
