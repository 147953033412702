import { AEDDetails } from './aed.model';
import { IBasicOrganization, Organization } from './organization.model';
import { ILabel, Label } from './label.model';

import { LOCAL_STORAGE } from '../constants/local-storage';
import { LocalStoreService } from '../services/local-store.service';
import { Location } from './location.model';

export interface IUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  phone: string;
  labels: ILabel[];
  organizations: IBasicOrganization[];
  profile_image?: string;
  is_active?: boolean;
  last_login?: string;
  status?: string;
  aeds?: AEDDetails;
  additional_permissions?: any[];
}

export class User {
  id: number = null;
  email: string;
  first_name: string;
  last_name: string;
  labels: Label[] = [];
  phone: string;
  organizations: Organization[] = [];
  locations: Location[] = [];
  role: string;
  profile_image?: string;
  notification_settings?: any[];
  aeds?: AEDDetails[] = [];
  is_active?: boolean;
  last_login?: string;
  status?: string;
  password?: string;
  email_updates?: boolean;
  additional_permissions?: any[];

  constructor(iUser) {
    if (iUser !== undefined && iUser !== null && iUser !== '') {
      if (iUser.id) {
        this.id = iUser.id;
      }
      this.email = iUser.email;
      this.first_name = iUser.first_name;
      this.last_name = iUser.last_name;
      this.role =
        iUser.role === 'admin' ? (iUser.role = 'Administrator') : iUser.role;
      this.phone = iUser.phone;
      this.profile_image = iUser.profile_image ? iUser.profile_image : '';
      this.status = iUser.status;
      this.last_login = iUser.last_login;
      this.is_active = iUser.is_active;
      if (iUser.notification_settings !== undefined) {
        this.notification_settings = iUser.notification_settings;
      }
      if (iUser.additional_permissions !== undefined) {
        this.additional_permissions = iUser.additional_permissions;
      }

      // For Register
      if (iUser.password !== undefined) {
        this.password = iUser.password;
      }
      if (iUser.email_updates !== undefined) {
        this.email_updates = iUser.email_updates;
      }
      //

      // adding organizations
      if (iUser.organizations !== undefined) {
        for (let each of iUser.organizations) {
          this.organizations.push(new Organization(each.organization));
        }
      }

      // adding labels
      if (iUser.labels !== undefined) {
        for (let each of iUser.labels) {
          this.labels.push(new Label(each));
        }
      }

      // adding locations
      if (iUser.locations !== undefined) {
        for (let each of iUser.locations) {
          this.locations.push(new Location(each));
        }
      }
      // adding aed
      if (iUser.aeds !== undefined) {
        for (let each of iUser.aeds) {
          this.aeds.push(new AEDDetails(each));
        }
      }
    }
  }

  getDisplayName() {
    const displayName =
      this.first_name !== null && this.last_name !== null
        ? this.first_name + ' ' + this.last_name
        : this.email;
    return displayName;
  }
  getUserName() {
    const displayName = this.first_name !== null && this.last_name !== null ? this.first_name + ' ' + this.last_name : 'None';
    return displayName;
  }
}

// For users list
export class UsersList {
  users: User[] = [];
  summary: {
    admin: number;
    manager: number;
    responder: number;
    users: number;
  };
  constructor(userList) {
    if (userList !== '') {
      for (let each of userList.data) {
        this.users.push(new User(each as IUser));
      }
      if (typeof userList.summary == 'object') {
        this.summary = {
          ...this.summary,
          ...userList.summary,
        };
      }
    } else {
      this.users = [];
      this.summary = {
        admin: 0,
        manager: 0,
        responder: 0,
        users: 0,
      };
    }
  }
  getDisplayName(firstname, lastname) {
    return firstname + ' ' + lastname;
  }
}

export class EditUser {
  is_active?: boolean;
  labels?: any[];
  role?: number;
  aeds?: any[];
  locations?: any[];
  org_id?: number;
  additional_permissions?: any[];
  addManageUser: boolean;
  orderAEDSupplies: boolean;
  createLabels: boolean;

  constructor(ut: any, private ls: LocalStoreService) {
    if (ut) {
      const mapping_data = this.ls.getItem(LOCAL_STORAGE.MAPPING_DATA);
      this.is_active = false;
      this.role = Number(ut.userRole);
      this.additional_permissions = [];
      let userPermission =[];
      let orderPermission =[];
      let labelPermission=[];
      if (this.role == mapping_data.user_roles[2]) {
          const permissions = mapping_data.additional_permissions;
          if (ut.addManageUser === true && permissions['Add & Manage Users']) {
              userPermission = Object.keys(permissions['Add & Manage Users']).map(Number);
          }
          if (ut.orderAEDSupplies === true && permissions['Order AEDs & Supplies']) {
              orderPermission = Object.keys(permissions['Order AEDs & Supplies']).map(Number);
          }
          if (ut.createLabels === true && permissions['Create Labels']) {
              labelPermission = Object.keys(permissions['Create Labels']).map(Number);
          }
          this.additional_permissions = [...userPermission,...orderPermission,...labelPermission];
      }

    }

  }

  // TODO: update values with APIs
  static userInfo() {
    return {
      name: '[First Name], [Last Name]',
      email: '[email]',
      phone: '[phone number]',
    };
  }
}

export class ForgotPassword {
  email: string;
}

export class ConfirmPassword {
  password: string;
  token: string;
  uidb64: string;
}

export class EditAssignment {
  name?: string;
  color?: string;

  constructor(eu) {
    this.name = eu.name;
    this.color = eu.color;
  }

  //  TODO: update values with APIs

  static userData(): EditAssignment[] {
    return [{ name: 'John Que', color: '' }];
  }

  static aedData(): EditAssignment[] {
    return [
      { name: 'AED NAME', color: '' },
      { name: 'AED NAME', color: '' },
      { name: 'AED NAME', color: '' },
    ];
  }

  static locationData(): EditAssignment[] {
    return [{ name: 'Franklin', color: '' }];
  }
}

export class SignupUser {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone: string;
  email_updates: boolean;

  constructor(signupData) {
    if (signupData !== undefined) {
      this.first_name = signupData.firstname;
      this.last_name = signupData.lastname;
      this.email = signupData.email;
      this.password = signupData.password;
      this.email_updates = signupData.agred;
      this.phone = signupData.phonenumber;
    }
  }
}

export class AddUser {
  is_active?: boolean;
  email?: string;
  labels?: any[];
  role?: number;
  aeds?: any[];
  locations?: any[];
  org_id?: number;
  additional_permissions?: any[];

  constructor(ut: any) {
    if (ut) {
      this.is_active = false;
      this.email = ut.email;
      this.role = Number(ut.userRole);
      this.additional_permissions = ut.additional_permissions;
    }
  }

  static roles() {
    return [
      { id: 'admin', value: 3, text: 'Administrator', checked: false },
      { id: 'manager', value: 2, text: 'Manager', checked: true },
      { id: 'responder', value: 1, text: 'Responder', checked: false },
    ];
  }
}

export class MapData {
  notification_types?: any[];
  notification_method?: any[];

  constructor(ut) {
    if (ut) {
      this.notification_types = ut.notification_settings.type_choices;
      this.notification_method = ut.notification_settings.method_choices;
    }
  }
  getMapTypes() {
    return Object.keys(this.notification_types).map((key) => ({
      type: key,
      value: this.notification_types[key],
    }));
  }
  getMapMethods() {
    return Object.keys(this.notification_method).map((key) => ({
      type: key,
      value: this.notification_method[key],
    }));
  }
}
export class changePwd {
  old_password: string;
  password: string;

  constructor(passwordData) {
    if (passwordData !== undefined) {
      this.old_password = passwordData.oldPassword;
      this.password = passwordData.newPassword;
    }
  }
}
export class HealthMapData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  health_data?: any[];
  constructor(health) {
    if (health) {
      this.health_data = health.health_issues.overall_health;
    }
  }
  getHealthData() {
    return Object.keys(this.health_data).map((key) => ({
      type: key,
      value: this.health_data[key],
    }));
  }
}
