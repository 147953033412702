export interface INotification {
  id?: number;
  type: string;
  alert_type: string;
  content?: string;
  time_stamp?: string;
  action?: string;
  expiration?: number;
  sub_category?: string;
  read?: boolean;
  active?: boolean;
  serial_number?: string;
  content_type?: string;
}
export class Notification {
  id?: number;
  type: string;
  alert_type: string;
  content?: string;
  time_stamp?: string;
  action?: string;
  expiration?: number;
  sub_category?: string;
  read?: boolean;
  active?: boolean;
  serial_number?: string;
  content_type?: string;
  display_text?: string;
  display_action?: string;
  action_route?: string;

  constructor(nt: INotification) {    
      this.id = nt.id;
      this.type = nt.type;
      this.alert_type = nt.alert_type;
      this.content = nt.content;
      this.time_stamp = nt.time_stamp;
      this.action = nt.action;
      this.expiration = nt.expiration;
      this.sub_category = nt.sub_category;
      this.read = nt.read;
      this.active = nt.active;
      this.serial_number = nt.serial_number;
      this.content_type = nt.content_type;
      
      if (nt.serial_number !== undefined) {
        this.display_text = nt.serial_number;
      }
      if (nt.sub_category !== undefined) {
        this.display_text = this.display_text + ': ' + nt.sub_category;
      }
      if (nt.content !== undefined) {
        this.display_text = this.display_text + ' ' + nt.content;
      }

      if (nt.sub_category !== undefined) {
        if (nt.sub_category === NOTIFICATION_CAT_PAD_STATUS) {
          this.display_action = NOTIFICATION_CAT_PAD_ACTION;
          this.action_route = '';
        } else if (nt.sub_category === NOTIFICATION_CAT_LOST) {
          this.display_action = NOTIFICATION_CAT_LOST_ACTION;
          this.action_route = '';
        } else if (nt.sub_category === NOTIFICATION_CAT_BATTERY) {
          this.display_action = NOTIFICATION_CAT_BATTERY_ACTION;
          this.action_route = '';
        } else if (nt.sub_category === NOTIFICATION_CAT_FUNC) {
          this.display_action = NOTIFICATION_CAT_FUNC_ACTION;
          this.action_route = '';
        }
      }
  }
}

export interface NotificationColumn {
  name: string;
  dataKey: string;
  position?: 'center' | 'right' | 'left';
  isSortable?: boolean;
}

export const NOTIFICATION_CAT_PAD_STATUS = "Pad Status";
export const NOTIFICATION_CAT_PAD_ACTION = "ORDER PADS";

export const NOTIFICATION_CAT_LOST = "Lost or Stolen";
export const NOTIFICATION_CAT_LOST_ACTION = "VIEW AED";

export const NOTIFICATION_CAT_BATTERY = "Battery Level";
export const NOTIFICATION_CAT_BATTERY_ACTION = "VIEW AED";

export const NOTIFICATION_CAT_FUNC = "Functional Status";
export const NOTIFICATION_CAT_FUNC_ACTION = "VIEW AED";