import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-av-full-card-summary-item-data-key-value',
    templateUrl: './av-full-card-summary-item-data-key-value.component.html',
    styleUrls: ['./av-full-card-summary-item-data-key-value.component.scss']
})
export class AvFullCardSummaryItemDataKeyValueComponent implements OnInit {
    @Input() color: string;
    @Input() key: string;
    @Input() value: string;
// eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {
    }

    ngOnInit(): void {
      this.color = 'item-'+this.color;
    }

}
