<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button *ngIf="layoutConf.isMobile" mat-icon-button id="sidenavToggle" (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open">
    <mat-icon>menu</mat-icon>
  </button>
  <app-av-account-name *ngIf="layoutConf.isMobile"></app-av-account-name>
  <!-- Language Switcher -->

  <span fxFlex="6" fxFlex.lt-md="0"></span>
  <egret-search-input-over *ngIf="!layoutConf.isMobile" fxFlex="grow" placeholder="" resultPage="/search"></egret-search-input-over>
  <span fxFlex="8" fxFlex.lt-sm="3"></span>
  <app-av-notification-profile fxLayoutAlign="end center" fxLayoutGap.lt-md="16px" fxLayoutGap.lt-sm="16px" class="nofify-style"></app-av-notification-profile>
</mat-toolbar>
