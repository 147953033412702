import { AccountCreateComponent } from './views/account/account-create/account-create.component';
import { AccountSelectComponent } from './views/account/account-select/account-select.component';
import { AccountWelcomeComponent } from './views/account/account-welcome/account-welcome.component';
import { ActivateAccountComponent } from './views/sessions/activate-account/activate-account.component';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthCheckGuard } from './shared/guards/authcheck.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { ConfirmPasswordComponent } from './views/sessions/confirm-password/confirm-password.component';
import { IncidentLayoutComponent } from './shared/components/layouts/incident-layout/incident-layout.component';
import { OrgGuard } from './shared/guards/org.guard';
import { Routes } from '@angular/router';
import { UserProfileComponent } from './views/users/user-profile/user-profile.component';
import { WelcomeLayoutComponent } from './shared/components/layouts/welcome-layout/welcome-layout.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        canActivate: [AuthCheckGuard],
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      },
      {
        path: 'forgot_reset_password/:token-id/:token',
        component: ConfirmPasswordComponent,
        data: { title: 'confirm-password' }
      },
      {
        path: 'activate/:token-id/:token',
        component: ActivateAccountComponent,
        data: { title: 'Activate' }
      }
    ]
  },
  {
    path: '',
    component: IncidentLayoutComponent,
    children: [
      {
        path: 'incidents',
        loadChildren: () => import('./views/incidents/incidents.module').then(m => m.IncidentssModule),
        data: { title: 'Incident Details' }
      }
    ]
  },
  {
    path: '',
    component: WelcomeLayoutComponent,
    children: [
      {
        path: 'welcome',
        component: AccountWelcomeComponent,
        canActivate: [AuthGuard],
        data: { title: 'Account Welcome' }
      },
      {
        path: 'account/create',
        component: AccountCreateComponent,
        canActivate: [AuthGuard],
        data: { title: 'Account create' }
      },
      {
        path: 'account/select',
        component: AccountSelectComponent,
        canActivate: [AuthGuard],
        data: { title: 'Account Select' }
      },
      {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        data: { title: 'User Profile' }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [OrgGuard, AuthGuard],
        data: { title: 'Dashboard' }
      },
      {
        path: 'fleet-map',
        loadChildren: () => import('./views/fleetmap/fleetmap.module').then(m => m.FleetmapModule),
        canActivate: [AuthGuard],
        data: { title: 'Fleet Map' }
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard, OrgGuard],
        data: { title: 'Users' }
      },
      {
        path: 'account',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard],
        data: { title: 'Account' }
      },
      {
        path: 'aed',
        loadChildren: () => import('./views/aed/aed.module').then(m => m.AedModule),
        canActivate: [AuthGuard, OrgGuard],
        data: { title: 'Aed' }
      },
      {
        path: 'cpr',
        loadChildren: () => import('./views/cpr/cpr.module').then(m => m.CprModule),
        canActivate: [AuthGuard, OrgGuard],
        data: { title: 'Cpr' }
      },
      {
        path: 'location',
        loadChildren: () => import('./views/location/location.module').then(m => m.LocationModule),
        canActivate: [AuthGuard, OrgGuard],
        data: { title: 'Location' }
      },
      {
        path: 'label',
        loadChildren: () => import('./views/label/label.module').then(m => m.LabelModule),
        canActivate: [AuthGuard],
        data: { title: 'Label' }
      },
      {
        path: 'incidents-auth',
        loadChildren: () => import('./views/incidents/incidents.module').then(m => m.IncidentssModule),
        canActivate: [AuthGuard],
        data: { title: 'Incident Details' }
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [AuthGuard, OrgGuard],
        data: { title: 'Notifications' }
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }

];
