import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-av-schedules',
  templateUrl: './av-schedules.component.html',
  styleUrls: ['./av-schedules.component.scss']
})

export class AvSchedulesComponent implements OnInit, AfterViewInit {
  EVERYDAY_VALUE = 20;
  EVERYDAY_TEXT = 'Everyday';
  EVERYDAY_ENABLED = true;

  WEEKDAYS_VALUE = 21;
  WEEKDAYS_TEXT = 'Week days';
  WEEKDAYS_ENABLED = true;

  WEEKEND_VALUE = 22;
  WEEKEND_TEXT = 'Weekend days';
  WEEKEND_ENABLED = true;

  scheduleForm: FormGroup;
  frequencyList: any[] = [];
  hoursFrom: any[] = [];
  hoursUpto: any[] = [];

  
  @Input() set tableData(data: any[]) {
    //this.setTableDataSource(data);
  }
  
  constructor(private fb: FormBuilder) { 
    this.frequencyList.push({
      value: '20',
      text: 'Everyday'
    });
    this.hoursFrom.push( {
      value: '01.00',
      text: '01.00'
    });
    this.hoursUpto.push( {
      value: '02.00',
      text: '02.00'
    });
  }

  ngOnInit(): void {
    this.scheduleForm = this.fb.group({
      timezone: new FormControl(''),
      frequency: new FormControl(''),
      fromhours: new FormControl(''),
      tohours: new FormControl(''),
      publicavailability: new FormControl(''),
      publicAvailabilityFields: this.fb.group({
        PA: this.fb.array([this.addPA()])
      })
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngAfterViewInit(): void {
    
  }

  // Public availability
  addPA() {
    return this.fb.group({
      timezone: [''],
      frequency: [''],
      hoursfrom: [''],
      hoursto: ['']
    });
  }

  addMorePA() {
    this.publicAvailablity.push(this.addPA());
   }

  get publicAvailablity() {
    const control = <FormArray>(<FormGroup>this.scheduleForm.get('publicAvailabilityFields')).get('PA');
    return control;
  }

  validateFrequency(event) {
    let frequencySelected = event.value;
    
  }

}

