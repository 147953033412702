import { Component, OnInit, ViewChild } from '@angular/core';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'app/shared/constants/messages';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { AccountService } from 'app/shared/services/account.service';
import { BlankInputValidator } from 'app/shared/helpers/blank-input.validator';
import { COUNTRIES } from 'app/shared/constants/filters';
import { Location } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { Organization } from 'app/shared/models/organization.model';
import { Router } from '@angular/router';
import { phoneNumberPattern } from 'app/shared/helpers/phone-number.validator';

@Component({
  selector: 'app-account-create',
  templateUrl: './account-create.component.html',
  styleUrls: ['./account-create.component.scss'],
})
export class AccountCreateComponent implements OnInit {

  organizationForm: FormGroup;
  // for snack bar
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  nameErr: string;
  formValidation: boolean;
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  countries = COUNTRIES;
  constructor(
    private accountService: AccountService,
    private router: Router,
    public _snackBar: MatSnackBar,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.setOrganizationForm();
  }

  setOrganizationForm() {
    this.organizationForm = new FormGroup({
      name: new FormControl('',[ Validators.required, Validators.maxLength(50), Validators.minLength(1), BlankInputValidator]),
      phoneNumber: new FormControl('',[Validators.required, Validators.maxLength(13), Validators.minLength(10), BlankInputValidator, phoneNumberPattern]),
      emailId: new FormControl('', [Validators.required, Validators.email, Validators.minLength(5)]),
      locationName: new FormControl('', [BlankInputValidator]),
      streetAddress: new FormControl('', [ Validators.maxLength(50), BlankInputValidator ]),
      streetAddress2: new FormControl('', [ Validators.maxLength(50), BlankInputValidator ]),
      city: new FormControl('', [ Validators.maxLength(10), BlankInputValidator ]),
      state: new FormControl('', [ Validators.maxLength(20) ]),
      zipCode: new FormControl('', [ Validators.maxLength(10), BlankInputValidator ]),
      country: new FormControl(''),
    });
  }

  get organizationFormCtrls() {
    return this.organizationForm.controls
  }

  createAccount() {
    if(this.organizationForm.valid) {
      this.progressBar.mode = 'indeterminate';
      this.formValidation = true;
      let errmessage: string;
      const organizationData = this.organizationForm.value;
      const submittedValues: Organization = new Organization(organizationData)
      this.accountService.createOrganization(submittedValues)
      .then((response: any)=>{
        this.progressBar.mode = 'determinate';
        if(response.status === 201){
          errmessage = SUCCESS_MESSAGES.create_account;
        } else {
          errmessage = ERROR_MESSAGES.create_account;
        }
        this.openSnakbar(errmessage);
        this.router.navigateByUrl('account/select');
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            if(error.response.data.name) {
              this.nameErr = error.response.data.name;
              this.organizationFormCtrls.name.setErrors({ nameErr: error.response.data.name});
            }
          }
        }
        
        errmessage = ERROR_MESSAGES.create_account;
        this.openSnakbar(errmessage);
        this.progressBar.mode = 'determinate';
      });
    } else {
      this.progressBar.mode = 'determinate';
      this.openSnakbar(ERROR_MESSAGES.create_account);
      return false;
    }
  }

  openSnakbar(message){
    this._snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000
    });
  }

  goBack() {
    this.location.back();
  }
}
