import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-av-pg-title-heading',
  templateUrl: './av-pg-title-heading.component.html',
  styleUrls: ['./av-pg-title-heading.component.scss']
})
export class AvPgTitleHeadingComponent {
  @Input() pg_title: string;
// eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
}
