import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'app/shared/models/user.model';
import { LayoutService } from 'app/shared/services/layout.service';
import { LoginService } from 'app/shared/services/login.service';
import { EgretNotifications2Component } from '../../egret-notifications2/egret-notifications2.component';
import { SearchService } from 'app/shared/search/search.service';
import { LOCAL_STORAGE } from 'app/shared/constants/local-storage';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { UserService } from 'app/shared/services/users.service';
@Component({
  selector: 'app-av-notification-profile',
  templateUrl: './av-notification-profile.component.html',
  styleUrls: ['./av-notification-profile.component.scss'],
})
export class AvNotificationProfileComponent implements OnInit {
  @Output() searchClick: EventEmitter<any> = new EventEmitter();
  @Input() notificPanel;
  @ViewChildren(EgretNotifications2Component) noti;

  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  public layoutConf: any;
  searchClicked = false;
  user: string;
  userDetails: any = { profile_image: '' };
  constructor(
    public loginService: LoginService,
    private layout: LayoutService,
    private router: Router,
    private ts: SearchService,
    private ls: LocalStoreService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    const userData = this.loginService.getUser();
    this.userService.profileNameUpdate.subscribe((name) => (this.user = name));
    this.userService.profileImageUpdate.subscribe(
      (profileImage) => (this.userDetails.profile_image  = profileImage)
    );
    const userDetails = this.ls.getItem(LOCAL_STORAGE.APP_USER);
    this.userDetails.profile_image = userDetails
      ? userDetails.profile_image
      : '';
    this.layoutConf = this.layout.layoutConf;
    const u = new User(userData);
    if (userDetails !== null) {
      this.user = userDetails.first_name + ' ' + userDetails.last_name;
    }
    
  }
  ngAfterViewInit() {
    this.ts.search.subscribe((mode: boolean) => {
      this.searchClicked = mode;
    });
  }

  gotoProfile() {
    this.router.navigateByUrl('profile');
  }

  gotoNotifications() {
    this.router.navigateByUrl('notifications');
  }

  search() {
    this.searchClicked = !this.searchClicked;
    this.ts.changeSearchStatus(this.searchClicked);
  }
}
