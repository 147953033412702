import { AbstractControl, ValidationErrors } from "@angular/forms"
import { ERROR_MESSAGES } from "../constants/messages";

export const phoneNumberPattern = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';
  let msg = "";
  if (!value) {
    return null
  }

  let pattern = /\+1\d[0-9]{9}|\+1\d[0-9]{13}$/g;
  if (pattern.test(value) === false || value.length > 13) {
    return {
      phoneNumberPattern: ERROR_MESSAGES.phone_number_validation
    }
  }
}
