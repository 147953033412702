export class AccountDetail {
    organization_name: string;
    organization_id: string;
    phone: string;
    email: string;
}

export class SubscriptionDetails {
    status: string;
    subscription_tier: string;
  }
  export class AccountWelcome {
    account_title?: string;
    account_content?:string;
    incident_title?: string;
    incident_content?:string;
    download_title?: string;
    download_content?:string;
    video_title?:string;


    constructor(account) {
      this.account_title = account.account_title;
      this.account_content = account.account_content;
      this.incident_title = account.incident_title;
      this.incident_content = account.incident_content;
      this. download_title = account. download_title;
      this.download_content = account.download_content;
      this.video_title = account.video_title;
    }
   // TODO: update values with APIs
    static welcomeData(): AccountWelcome {
      return {
        account_title: 'There are no accounts linked with your profile.',
        account_content: 'Create an account to add and manage AEDs, manage your CPR Certifications, and access your incidents.',
        incident_title: 'View detailed Incident Reports',
        incident_content: 'When an Avive AED is used in an Emergency, a detailed Incident Report is created and can be accessed  by searching by Incident ID.',
        download_title: 'Become an Avive Responder',
        download_content: 'Download the Avive App to become a responder. Responders will be notified of nearby Sudden Cardiac Arrest emergenices and will be directed to a nearby AED to take to the incident.',
        video_title:'Increasing Sudden Cardiac Arrest’s with Avive’s 4-Minute City'
      }
    }

}
