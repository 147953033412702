import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { RouterStateSnapshot } from "@angular/router";

import { Injectable } from "@angular/core";
import { LoginService } from "../services/login.service";
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private loginService: LoginService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.loginService.isLoggedIn().pipe(
      map((isLogged: boolean) => {
        if (!isLogged) {
          return this.router.createUrlTree(['/sessions/signin'], {
            queryParams: {
              return: state.url
            }
          });
        }
        return true;
      })
    );
  }
} 
