import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import  appVersion from '../../../../../../appVersion.json';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ProgressbarService } from 'app/shared/services/progressbar.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  public appVer: string;
  progressbarstatus: any;

  constructor(
    public translate: TranslateService,
    private ps: ProgressbarService
  ) {
    // Translator init
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit() {
    this.appVer= appVersion.appVersion;
  }

  ngAfterViewInit() {
    this.ps.progressBar.subscribe((mode: any) =>{
      this.progressBar.mode = mode ? mode : 'determinate';
    })
  }
}
