import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-av-full-card-summary-item-data-number',
  templateUrl: './av-full-card-summary-item-data-number.component.html',
  styleUrls: ['./av-full-card-summary-item-data-number.component.scss']
})
export class AvFullCardSummaryItemDataNumberComponent implements OnInit {
  @Input() itemValue: string;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
// eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

}
