import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ThemeService } from '../../services/theme.service';
import { Subscription } from 'rxjs';
import { ILayoutConf, LayoutService } from 'app/shared/services/layout.service';
import { LoginService } from 'app/shared/services/login.service';
import { Router } from '@angular/router';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html',
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  accountName: string = '';

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public loginService: LoginService,
    private router: Router,
    private ls: LocalStoreService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === 'icon'
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;
    this.accountService.accountName.subscribe((account: any) => {
      this.accountName = account ? account.name : '';
    });
  }
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }

  goToAccountSelect() {
    this.router.navigateByUrl('account/select');
  }

  ellipsify(str) {
    if (str.length > 10) {
      return str.substring(0, 15) + '...';
    } else {
      return str;
    }
  }
  goToDashBoard() {
    this.router.navigateByUrl('dashboard')
  }
}
