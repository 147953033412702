import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountWelcome} from '../../../shared/models/account.model';

@Component({
  selector: 'app-account-welcome',
  templateUrl: './account-welcome.component.html',
  styleUrls: ['./account-welcome.component.scss']
})
export class AccountWelcomeComponent implements OnInit {
  accountDetails = AccountWelcome.welcomeData();
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goToCreateAccount(){
    this.router.navigateByUrl('account/create');
  }

}
