import {IUser, User} from './user.model';

export interface ILabel {
    id?: number;
    name: string;
    type: number;
    org_id?: number;
    user?: IUser[];
}

export class Label {
    id?: number;
    name: string;
    type: number;
    org_id?: number;
    user?: User[];

    constructor(lt: ILabel) {
        this.id = lt.id;
        this.name = lt.name;
        this.id = lt.id;
        this.type = lt.type;
        this.org_id = lt.org_id;
        this.user = [];
        if (lt.user !== undefined) {
            for (let each of lt.user) {
                this.user.push(new User(lt.user));
            }
        }
    }
}

export class LabelExists  {
    status: number;
    message: string;
    constructor(data) {
        this.status = data.status?data.status:'';
        this.message = data.message?data.message:'';
    }
}
