import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';
import {LOCAL_STORAGE} from '../constants/local-storage';
import {LocalStoreService} from '../services/local-store.service';
import {LoginService} from '../services/login.service';

@Injectable()
export class OrgGuard implements CanActivate {
  constructor(
      private router: Router,
      private loginService: LoginService,
      private ls: LocalStoreService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const account = await this.ls.getItem(LOCAL_STORAGE.ACCOUNT);
    const user = await this.loginService.getUser();
    if (account) {
      return true;
    } else if (user && user.organizations) {
      if (user.organizations.length === 0) {
        this.router.navigateByUrl('welcome');
      } else if (user.organizations.length === 1) {
        this.ls.setItem(LOCAL_STORAGE.ACCOUNT, user.organizations[0]);
        return true;
      } else if (user.organizations.length > 1) {
        this.router.navigateByUrl('account/select');
        return true;
      } else {
        this.router.navigate(['/sessions/signin'], {
          queryParams: {
            return: state?.url
          }
        });
        return false;
      }
    } else {
      this.router.navigate(['/sessions/signin'], {
        queryParams: {
          return: state.url
        }
      });
      return false;
    }
    return true;
  }
}
