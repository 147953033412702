import {Injectable} from '@angular/core';
import axios from 'axios';
import {usersDetails} from 'app/shared/models/users-table.model';
import {URLS} from './urls';
import {AddUser,changePwd} from '../models/user.model';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
  profileImageUpdate = new Subject<string>();
  profileNameUpdate = new Subject<string>();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {
    }

    async getUser(org_id, pageIndex, searchParams) {
        // eslint-disable-next-line prefer-const, @typescript-eslint/no-explicit-any
        let params: any = {};
        if (searchParams.name) {
            params.fuzzy = encodeURI(searchParams.name);
        }
        if (searchParams.role) {
            params.role = searchParams.role;
        }
        if (searchParams.label) {
            params.label = searchParams.label;
        }
        if (pageIndex) {
            params.page_no = pageIndex;
        }
        if (org_id) {
            params.org_id = org_id;
        }
        if (searchParams.loc_id) {
            params.loc_id = searchParams.loc_id;
        }
        return await axios.get(URLS.user, {params});
    }

    async getUserDetails(id, orgId) {
        const params = {
            org_id: orgId,
        };
        return await axios.get<usersDetails>(URLS.user + id + '/', {params});
    }

    async addUser(org_id, userDetails: AddUser) {
        userDetails.org_id = org_id;
        return await axios.post<AddUser>(URLS.user, userDetails);
    }

    async updateUserDetails(id, orgId, userDetails) {
        // eslint-disable-next-line prefer-const, @typescript-eslint/no-explicit-any
        let params: any = {};
        params.org_id = orgId;
        return await axios.patch<usersDetails>(URLS.user + id + '/', userDetails, {
            params,
        });
    }

    async getUserProfile(id) {
        return await axios.get<usersDetails>(URLS.user + id + '/');
    }

    async uploadProfileImage(id, formData) {
        return await axios.patch<usersDetails>(
            URLS.user + id + '/upload_photo/',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
    }
    async updateProfile(id,userDetails) {
      return await axios.patch<usersDetails>(URLS.user + id + '/', userDetails);
    }
    async changePassword(id,passwordDetails) {
      return await axios.patch<changePwd>(URLS.user + id + '/change_password/',passwordDetails);
  }
  async deleteUser(id, orgId) {
    // eslint-disable-next-line prefer-const, @typescript-eslint/no-explicit-any
    let params: any = {};
    params.org_id = orgId;
    return await axios.delete<usersDetails>(URLS.user + id + '/', {params});
  }
}
