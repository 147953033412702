import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-av-assignments-label',
  templateUrl: './av-assignments-label.component.html',
  styleUrls: ['./av-assignments-label.component.scss']
})
export class AvAssignmentsLabelComponent implements OnInit {
  @Input() label: string;
  @Input() optional: boolean;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
// eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

}
