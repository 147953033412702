import { AbstractControl, ValidationErrors } from "@angular/forms"

export const BlankInputValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';
  let msg = "";
  if (!value) {
    return null
  }

  if (!value.replace(/\s/g, '').length) {
    return {
      blankSpaceOnly: 'Please enter a valid input'
    }

  }
}
