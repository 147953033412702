import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-av-pg-title-buttons',
  templateUrl: './av-pg-title-buttons.component.html',
  styleUrls: ['./av-pg-title-buttons.component.scss']
})
export class AvPgTitleButtonsComponent implements OnInit {
// eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
// eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

}
