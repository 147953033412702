import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NO_USER_SELECT } from 'app/shared/constants/users';
import { NOTIFICATION_CAT_BATTERY_ACTION, NOTIFICATION_CAT_FUNC_ACTION, NOTIFICATION_CAT_LOST_ACTION, NOTIFICATION_CAT_PAD_ACTION } from 'app/shared/models/notification.model';
import { DashboardService } from 'app/shared/services/dashboard.service';

@Component({
  selector: 'app-av-table-notifications',
  templateUrl: './av-table-notifications.component.html',
  styleUrls: ['./av-table-notifications.component.scss']
})

export class AvTableNotificationsComponent implements OnInit, AfterViewInit {
  mode: ProgressSpinnerMode;
  noRecordsFound = NO_USER_SELECT.first_name;
  
  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[] = ['time_stamp', 'display_text', ' '];
  @ViewChild(MatPaginator, {static: false}) matPaginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) matSort: MatSort;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orgDetails: any = { id: '', name: '' };

  recordsFound = true;
  @Input() isPageable = false;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() tableColumns: any[];
  @Input() rowActionIcon: string;
  @Input() paginationSizes: number[] = [5, 10, 15];
  @Input() defaultPageSize = this.paginationSizes[1];
  @Input() isHeader = false;

  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() set orgInfo(orgDetails: any[]) {
    this.orgDetails = orgDetails;
  }
// eslint-disable-next-line @typescript-eslint/no-empty-function
  @Input() set tableData(data: Notification[]) {
    
  }
  
  constructor(private dashboardService: DashboardService) { 
    this.dashboardService.notificationsDataUpdate.subscribe((notifications) => {
      if (notifications != null) {
        this.setTableDataSource(notifications);
      }      
    });
  }

// eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.matPaginator;
  }

  setTableDataSource(data) {
    this.tableDataSource = new MatTableDataSource<Notification>(data);
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
    if (data != null) {
      if (data.length > 0) {
        this.recordsFound = true;
      } else {
        this.recordsFound = false;
      }  
    } else {
      this.recordsFound = false;
    }  
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  sortTable(sortParameters: Sort) {
    sortParameters.active = this.tableColumns.find(column => column.name === sortParameters.active).dataKey;
    this.sort.emit(sortParameters);
  }

  emitRowAction(row) {
    this.rowAction.emit(row);
  }

  goToPage(notificationElement) {   
    // TODO: to make the route to the correct page after API response finalised. 
    let navigateUrl = '';
    if (notificationElement.display_action == NOTIFICATION_CAT_PAD_ACTION) {
      navigateUrl = 'aed/details/' + 192;
    } else if (notificationElement.display_action == NOTIFICATION_CAT_LOST_ACTION) {
      navigateUrl = 'aed/details/' + 192;
    } else if (notificationElement.display_action == NOTIFICATION_CAT_BATTERY_ACTION) {
      navigateUrl = 'aed/details/' + 192;
    } else if (notificationElement.display_action == NOTIFICATION_CAT_FUNC_ACTION) {
      navigateUrl = 'aed/details/' + 192;
    }
    if (navigateUrl !== '') {
      //TODO: wait till notification api response corrected.
      //this.router.navigateByUrl(navigateUrl);
    }
    
  }

}

