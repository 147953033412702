import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-av-dialogue-box',
  templateUrl: './av-dialogue-box.component.html',
  styleUrls: ['./av-dialogue-box.component.scss']
})
export class AvDialogueBoxComponent implements OnInit {
  layoutConf: any;
  @Output() closeDlg: EventEmitter<any> = new EventEmitter();
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  @Input() pop_title: string;
  @Input() isDisable = false;
  @Input() isHide = false;
  @Input() action: string;
  @Input() closeText: string;
  @Input() isCancel= false;
  constructor( ) {
    this.action = 'SAVE';
    this.closeText = 'EXIT';
  }

// eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void { }

  closeDialogue() {
    this.closeDlg.emit();
  }

  save() {
    this.saveData.emit();
  }
}
