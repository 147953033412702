import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DIALOGUE_BOX_SIZE } from 'app/shared/constants/utils'

@Injectable({
  providedIn: 'root'
})
export class DialogueboxService {
  minwidth: string;

  constructor(
    public dialog: MatDialog,
    public breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 280px)']).subscribe(result => {
      this.minwidth = result.matches ? DIALOGUE_BOX_SIZE.minWidthGalaxyFold : DIALOGUE_BOX_SIZE.minWidth;
    });
   }

  openDialogueBox(component, data){
    return this.dialog.open(component, {
      width: DIALOGUE_BOX_SIZE.width,
      minWidth: this.minwidth,
      data
    });
  }
}
