import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackBar } from 'app/shared/models/snackbar.model'

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  durationInSeconds = 1;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  openSnackBar(inputs: SnackBar) {
    this._snackBar.open(inputs.message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: inputs.time * 1000
    });
  }
}
