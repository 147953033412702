<div class="container" fxFlex="100">
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" class="mb-16">
    <app-av-pg-title-heading pg_title="{{heading}}"></app-av-pg-title-heading>
    <span fxFlex></span>
    <button class="header-cancel" title="" mat-stroked-button (click)="closeDialogue()" matTooltip="{{closeText}}">{{closeText}}</button>
    <button class="header-save" title="" mat-flat-button color="primary" [disabled]="isDisable" (click)="save()" matTooltip="{{action}}">{{action}}</button>
    <button class="close-icon" title="" (click)="closeDialogue()" mat-icon-button aria-label="Exit" matTooltip="{{closeText}}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="dlg-contents"><ng-content></ng-content></div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <button class="lower-save" mat-flat-button color="primary" (click)="save()" [disabled]="isDisable" fxFlex="100" matTooltip="{{action}}" title="">{{action}}</button>
  </div>
</div>
