import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-av-card-filter',
  templateUrl: './av-card-filter.component.html',
  styleUrls: ['./av-card-filter.component.scss']
})
export class AvCardFilterComponent implements OnInit {
// eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
// eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {

  }

}
