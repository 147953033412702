import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-av-pg-title-button',
  templateUrl: './av-pg-title-button.component.html',
  styleUrls: ['./av-pg-title-button.component.scss']
})
export class AvPgTitleButtonComponent implements OnInit {
  @Input() name: string;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
// eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

}
