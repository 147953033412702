import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-av-card',
  templateUrl: './av-card.component.html',
  styleUrls: ['./av-card.component.scss']
})
export class AvCardComponent implements OnInit {
// eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
// eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

}
